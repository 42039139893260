(function () {
    'use strict';

    angular
        .module('komexLoyaltyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('home.login', {
                parent: 'home',
                url: '/',
                data: {
                    authorities: []
                },
                templateUrl: 'app/components/login/login.html',
                controller: 'LoginController',
                controllerAs: 'vm'
            });
    }
})();
