(function () {
    'use strict';


    angular.module('komexLoyaltyApp')
        .factory('MenuService', MenuService);

    MenuService.$inject = ["$resource"];

    function MenuService($resource) {
        return $resource('api/menu', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    }
})();
