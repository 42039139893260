(function () {
    'use strict';

    angular
        .module('komexLoyaltyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('account.personal-data', {
            parent: 'account',
            url: '/konto/dane-osobowe',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Dane osobowe'
            },
            templateUrl: 'app/account/personal-data/personal-data.html',
            controller: 'PersonalDataController',
            controllerAs: 'vm',
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('personalData');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
