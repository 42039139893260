(function () {
    'use strict';

    angular
        .module('komexLoyaltyApp')
        .factory('ContactService', ContactService);

    ContactService.$inject = ['$resource'];

    function ContactService($resource) {
        var service = $resource('api/contact', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                isArray: true,
                params: { contact: null, customerId: null },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });

        return service;
    }
})();
