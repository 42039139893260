(function () {
    'use strict';

    angular.module('komexLoyaltyApp')
        .directive('register', register);

    register.$inject = ['$sce'];

    /** @ngInject */
    function register($sce) {
        return {
            restrict: 'E',
            templateUrl: 'app/components/register/register.html',
            controller: 'RegisterController',
            controllerAs: 'vm'
        };
    }
})();
