(function () {
    'use strict';

    angular.module('komexLoyaltyApp')
        .directive('menu', menu);

    menu.$inject = ['MenuService'];

    /** @ngInject */
    function menu(MenuService) {
        return {
            restrict: 'E',
            templateUrl: 'app/layouts/menu/menu.html',
            controller: 'MenuController',
            controllerAs: 'vm',
            replace: true,
            scope: {
                level: '@'
            },
            link: function ($scope, el) {
                var menuData = {};

                MenuService.get().$promise.then(function (data) {
                    menuData = data;
                    menuData.menuSource = JSON.parse(menuData.menuSource);

                    $scope.menuItems = list_to_tree(menuData.menuSource, $scope.level);
                });

                function list_to_tree(list, level) {
                    var map = {}, node, roots = [], i;
                    for (i = 0; i < list.length; i += 1) {
                        map[list[i].id] = i; // initialize the map
                        list[i].children = []; // initialize the children

                        node = list[i];
                        if (node.data.url) {
                            node.data.pfilename = node.data.url.replace('/', '');
                        }
                        if (node.parent !== "#") {
                            if (level) {
                                var parentNode = list[map[node.parent]];
                                if (node.data && node.data.url && (node.data.url.indexOf(level) != -1 ||
                                    (parentNode.data && parentNode.data.url && list[map[node.parent]].data.url.indexOf(level) != -1))) {
                                    list[map[node.parent]].children.push(node);
                                } else {
                                    continue;
                                }
                            } else {
                                list[map[node.parent]].children.push(node);
                            }
                            // if you have dangling branches check that map[node.parentId] exists
                        } else {
                            roots.push(node);
                        }
                    }
                    // for (i = 0; i < roots.length; i += 1) {

                    // }
                    return roots;
                }

                function searchTree(roots, level) {

                    var stack = [], node, ii;
                    stack.push(roots);

                    while (stack.length > 0) {
                        node = stack.pop();
                        if (node.data.url == level) {
                            alert(JSON.stringify(node));
                            // Found it!
                            break;
                        } else if (node.children && node.children.length) {
                            for (ii = 0; ii < node.children.length; ii += 1) {
                                stack.push(node.children[ii]);
                            }
                        }
                    }

                }
            }
        };
    }

})();
