(function () {
    'use strict';

    angular
        .module('komexLoyaltyApp')
        .controller('RegisterController', RegisterController);


    RegisterController.$inject = ['$translate', '$timeout', 'Auth', 'LoginService', 'errorConstants', '$state', 'AgreementsService'];

    function RegisterController($translate, $timeout, Auth, LoginService, errorConstants, $state, AgreementsService) {
        var vm = this;

        vm.registerDisabled = false;

        vm.error = null;
        vm.errorUserExists = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.registerAccount = {};
        vm.agreementsValid = true;
        vm.registerAccount.termsAgreenent = false;
        vm.success = null;
        vm.requestResetPassword = requestResetPassword;
        vm.agreementsAll = [];
        vm.userAgreements = {};
        vm.everythingChecked = false;
        vm.checkEverything = checkEverything;

        loadAllAgreements();

        $timeout(function () {
            angular.element('#login').focus();
        });

        function register(registerForm) {
            var formattedAgreements = saveAgreements();

            if (registerForm.$valid && vm.agreementsValid) {
                vm.registerDisabled = true;
                vm.registerAccount.email = vm.registerAccount.login;
                vm.registerAccount.langKey = $translate.use();
                vm.registerAccount.agreementsRequest = {};
                vm.registerAccount.agreementsRequest.agreements = formattedAgreements;
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;

                Auth.createAccount(vm.registerAccount).then(function () {
                    vm.success = 'OK';
                    vm.registerDisabled = false;
                }).catch(function (response) {
                    vm.registerDisabled = false;
                    vm.success = null;
                    if (response.status === 400 && angular.fromJson(response.data).type === errorConstants.LOGIN_ALREADY_USED_TYPE) {
                        vm.errorUserExists = 'ERROR';
                    } else if (response.status === 400 && angular.fromJson(response.data).type === errorConstants.EMAIL_ALREADY_USED_TYPE) {
                        vm.errorEmailExists = 'ERROR';
                    } else {
                        vm.error = 'ERROR';
                    }
                });
            } else {
                vm.termsAgreenentRequired = true;
                angular.forEach(registerForm.$error.required, function (field) {
                    field.$setDirty();
                });
            }
        }

        function requestResetPassword() {
            $state.go('home.requestReset');
        }

        function loadAllAgreements() {
            AgreementsService.get().$promise.then(function (result) {

                if (result && result.agreements) {
                    result.agreements.forEach(function (agreement) {
                            vm.agreementsAll.push(agreement);
                            vm.userAgreements[agreement.id] = agreement;
                            vm.userAgreements[agreement.id].required = agreement.required;
                            vm.userAgreements[agreement.id].agreed = false;
                        }
                    );
                }
            }, function (result) {
                console.log('error: ' + JSON.stringify(result));
            });
        }


        function saveAgreements() {
            vm.agreementsValid = true;
            if (vm.agreementsAll) {
                for (var i = 0; i < vm.agreementsAll.length; i++) {
                    var agreement = vm.agreementsAll[i];
                    if (agreement.required && !vm.userAgreements[agreement.id].agreed) {
                        vm.agreementsValid = false;
                        break;
                    }
                }
            }
            var formattedAgreement = [];
            if (vm.agreementsValid) {
                for (var index in vm.userAgreements) {
                    formattedAgreement.push({id: vm.userAgreements[index].id, agreed: vm.userAgreements[index].agreed});
                }
            }
            return formattedAgreement;
        }

        function checkEverything() {
            vm.everythingChecked = !vm.everythingChecked;
            for (var agreement in vm.userAgreements) {
                vm.userAgreements[agreement].agreed = vm.everythingChecked;
            }
        }
    }
})();
