(function () {
    'use strict';

    angular
        .module('komexLoyaltyApp')
        .controller('DesktopController', DesktopController);

    DesktopController.$inject = ['$state', 'Principal', 'Auth', 'JhiLanguageService', '$translate', 'CustomerLoyaltyService','$window'];

    function DesktopController($state, Principal, Auth, JhiLanguageService, $translate, CustomerLoyaltyService, $window) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.customerId = null;
        vm.success = null;
        vm.logout = logout;
        vm.customerLoyalty = null;
        vm.couponClick = couponClick;
        if ($state.$current.data && $state.$current.data.pageTitle) {
            $window.document.title = $state.$current.data.pageTitle;
        }

        Principal.identity().then(function (account) {
            if (account) {
                vm.customerId = account.customerId;
                loadCardsAndCupons();
            }
        });

        function loadCardsAndCupons() {
            CustomerLoyaltyService.get({customerId: vm.customerId}).$promise.then(function (result) {
                if (result) {
                    vm.customerLoyalty = result;
                }
            }, function (result) {
                console.log('error: ' + JSON.stringify(result));
            });
        }

        function save() {
            Auth.updateAccount(vm.customerId).then(function () {
                vm.error = null;
                vm.success = 'OK';
                Principal.identity(true).then(function (account) {
                    vm.customerId = copyAccount(account);
                });
                JhiLanguageService.getCurrent().then(function (current) {
                    if (vm.customerId.langKey !== current) {
                        $translate.use(vm.customerId.langKey);
                    }
                });
            }).catch(function () {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }

        function logout() {
            Auth.logout();
            $state.go('home.login');
        }

        function couponClick(coupon) {
            coupon.showCode = true;
        }
    }
})();
