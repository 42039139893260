(function () {
    'use strict';

    angular
        .module('komexLoyaltyApp')
        .controller('PersonalDataController', PersonalDataController);

    PersonalDataController.$inject = ['$state', 'Principal', 'Auth', 'JhiLanguageService', '$translate', '$window', '$rootScope', 'AgreementsService'];

    function PersonalDataController($state, Principal, Auth, JhiLanguageService, $translate, $window, $rootScope, AgreementsService) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.personalDataAccount = null;
        vm.success = null;
        vm.logout = logout;
        vm.showAddChild = true;
        vm.uiOptions = {modelViewValue: true};
        if ($state.$current.data && $state.$current.data.pageTitle) {
            $window.document.title = $state.$current.data.pageTitle;
        }

        vm.newPassword = '';
        vm.confirmPassword = '';
        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.errorPassword = null;
        vm.successPassword = null;
        vm.passwordMatch = passwordMatch;
        vm.agreements = {};
        vm.agreementsAll = [];

        vm.everythingChecked = true;
        vm.checkEverything = checkEverything;

        loadAllAgreements();

        vm.addChild = function () {
            var newItemNo = vm.children.length + 1;
            vm.children.push({
                id: newItemNo,
                childFirstName: '',
                childLastName: '',
                childBirthtDate: '',
                childGender: ''
            });
            if (newItemNo >= 4) {
                vm.showAddChild = false;
            }
        };

        vm.removeChild = function (child) {
            var newItemNo = vm.children.length - 1;
            if (newItemNo !== 0) {
                vm.children.pop();
                vm.showAddChild = true;
            }
        };


         function loadPersonalData() {
             Principal.identity(true).then(function (account) {
                 vm.personalDataAccount = account;

                 if (vm.agreementsAll) {
                     var copy = [];

                     for(var i = 0;i < vm.agreementsAll.length; i++) {
                         copy[i] = angular.copy(vm.agreementsAll[i]);
                         copy[i].clause = null;
                         if (vm.personalDataAccount.agreements[i]) {
                             copy[i].agreed = vm.personalDataAccount.agreements[i].agreed;
                         }
                     }
                     vm.personalDataAccount.agreements = copy;
                 }
                 if (vm.personalDataAccount.zipCode) {
                     vm.personalDataAccount.zipCode = vm.personalDataAccount.zipCode.replace('-', '');
                 }
                 if (account.children && account.children.length > 0) {
                     vm.children = [];
                     account.children.forEach(function (child) {
                         child.childBirthtDate = child.childBirthtDate.replace('-', '');
                         if (child.childBirthtDate !== "19000101") {
                             vm.children.push(child);
                         }
                     });
                 } else {
                     vm.children = [{id: 1, childBirthtDate: '', childGender: ''}];
                 }
             });
         }

        function save(form) {
            var agreementsValid = true;
            if (vm.personalDataAccount.agreements) {
                for (var i = 0;i < vm.personalDataAccount.agreements.length; i++) {
                    var agreement = vm.personalDataAccount.agreements[i];
                    if (agreement.required && !agreement.agreed) {
                        agreementsValid = false;
                        break;
                    }
                }
            }

            if (form.$valid && agreementsValid) {
                var children = [];
                vm.children.forEach(function (child) {
                    if (child.childBirthtDate) {
                        children.push({
                            id: child.id,
                            childBirthtDate: formatDate(child.childBirthtDate),
                            childGender: child.childGender
                        })
                    }
                });
                vm.personalDataAccount['children'] = children;
                if (vm.personalDataAccount.zipCode) {
                    vm.personalDataAccount.zipCode = formatZipCode(angular.copy(vm.personalDataAccount.zipCode));
                }

                Auth.updateAccount(vm.personalDataAccount).then(function () {
                    vm.error = null;
                    vm.success = 'OK';
                    setTimeout(function () {
                        vm.success = null;
                        $rootScope.$apply(vm.success);
                    }, 8000);
                    JhiLanguageService.getCurrent().then(function (current) {
                        if (vm.personalDataAccount.langKey !== current) {
                            $translate.use(vm.personalDataAccount.langKey);
                        }
                    });
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            } else {
                angular.forEach(form.$error.required, function (field) {
                    field.$setDirty();
                });
            }
        }

        function logout() {
            Auth.logout();
            $state.go('home.login');
        }

        function formatDate(date) {
             if(date.length == 8) {
                 return date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6, 8);
             }
             return date;
        }

        function formatZipCode(zipCode) {
            if (zipCode.length == 5) {
                return zipCode.substring(0, 2) + '-' + zipCode.substring(2, 5);
            }
            return zipCode;
        }

        function changePassword(form) {
            if (form.$valid) {
                if (vm.newPassword !== vm.confirmPassword) {
                    vm.errorPassword = null;
                    vm.successPassword = null;
                    vm.doNotMatch = true;
                } else {
                    vm.doNotMatch = false;
                    Auth.changePassword(vm.newPassword).then(function () {
                        vm.errorPassword = null;
                        vm.successPassword = true;
                        setTimeout(function () {
                            vm.successPassword = null;
                            $rootScope.$apply(vm.successPassword);
                        }, 8000);

                    }).catch(function () {
                        vm.successPassword = null;
                        vm.errorPassword = 'ERROR';
                    });
                }
            } else {
                angular.forEach(form.$error.required, function (field) {
                    field.$setDirty();
                });
            }
        }

        function passwordMatch() {
            vm.doNotMatch = vm.newPassword !== vm.confirmPassword;
        }

        function loadAllAgreements() {
            AgreementsService.get().$promise.then(function (result) {
                if (result && result.agreements) {
                    vm.agreementsAll = result.agreements;
                    result.agreements.forEach(function (agreement) {
                            vm.agreements[agreement.id] = agreement;
                        }
                    );
                }
                loadPersonalData();
            }, function (result) {
                console.log('error: ' + JSON.stringify(result));
            });
        }

        function checkEverything() {
            vm.everythingChecked = !vm.everythingChecked;

            for (var agreement in vm.personalDataAccount.agreements) {
                vm.personalDataAccount.agreements[agreement].agreed = vm.everythingChecked;
            }
        }
    }
})();
