(function () {
    'use strict';

    angular
        .module('komexLoyaltyApp')
        .controller('CMSController', CMSController);

    CMSController.$inject = ['$scope', '$stateParams', 'cmsPage', '$sce', '$window', '$state'];

    function CMSController($scope, $stateParams, cmsPage, $sce, $window, $state) {
        var lang = 'pl';
        if (cmsPage) {
            var pversion = cmsPage.pversion;
            var taracotUrl = cmsPage.taracotUrl;
            var pdata_lang = cmsPage.pdata[lang];
            var $pcontent = pdata_lang[pversion].pcontent;
            var title = pdata_lang[pversion].ptitle;

            $scope.pcontent = $sce.trustAsHtml($pcontent);
            $scope.title = title;
            $window.document.title = title;
        } else {
            $state.go('home.login');
        }
    }
})();
