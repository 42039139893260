(function () {
    'use strict';

    angular.module('komexLoyaltyApp')
        .controller('PartController', PartController);

    PartController.$inject = ['$scope', '$translate', '$sce'];

    function PartController($scope, $translate, $sce) {
    }
})();
