(function() {
    'use strict';

    angular
        .module('komexLoyaltyApp')
        .controller('AccountController', AccountController);

    AccountController.$inject = ['$scope', 'Principal', 'LoginService', '$state','Auth','CustomerLoyaltyService','$window','allPages'];

    function AccountController ($scope, Principal, LoginService, $state, Auth, CustomerLoyaltyService,$window, allPages) {
        var vm = this;
        vm.currentAccountView = 'register';
        vm.account = null;
        vm.isAuthenticated = false;
        vm.login = LoginService.open;
        vm.logout = logout;
        vm.loadCardsAndCupons = loadCardsAndCupons;
        vm.getLogoHref = getLogoHref;

        vm.allPages = allPages;

        if ($state.$current.data && $state.$current.data.pageTitle) {
            $window.document.title = $state.$current.data.pageTitle;
        }

        vm.customerLoyalty = null;
        vm.customerId = null;

        vm.register = register;
        vm.scrollToLogo = scrollToLogo;

        $scope.$on('authenticationSuccess', function() {
            getAccount();

            $state.go('account.desktop');
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                if (account) {
                    vm.account = account;
                    vm.isAuthenticated = Principal.isAuthenticated;

                    vm.customerId = account.customerId;
                    loadCardsAndCupons();
                }
            });
        }
        function register () {
            $state.go('register'); // TODO !!!
        }

        function scrollToLogo() {
            $("body,html").animate({scrollTop: 0}, "slow");
        }

        function logout() {
            Auth.logout();
            $state.go('home.login');
        }


        function loadCardsAndCupons() {
            CustomerLoyaltyService.get({customerId: vm.customerId}).$promise.then(function (result) {
                if (result) {
                    vm.customerLoyalty = result;
                }
            }, function (result) {
                if (result) {
                    console.log('error: ' + JSON.stringify(result));
                }
            });
        }

        function getLogoHref() {
            if (vm.isAuthenticated) {
                $state.go('account.desktop');
            } else {
                $state.go('home.login');
            }
        }

    }
})();
