(function() {
    'use strict';

    angular
        .module('komexLoyaltyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home.requestReset', {
            url: '/reset-hasla',
            data: {
                authorities: []
            },
            templateUrl: 'app/components/password/request/reset.request.html',
            controller: 'RequestResetController',
            controllerAs: 'vm',
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reset');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
