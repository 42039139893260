(function () {
    'use strict';

    angular
        .module('komexLoyaltyApp')
        .controller('ContactController', ContactController);

    ContactController.$inject = ['$state', 'Principal', 'Auth', 'ContactService', '$translate', '$rootScope','$window'];

    function ContactController($state, Principal, Auth, ContactService, $translate, $rootScope, $window) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.success = null;
        vm.logout = logout;
        vm.showAddChild = true;
        vm.uiOptions = {modelViewValue: true};
        if ($state.$current.data && $state.$current.data.pageTitle) {
            $window.document.title = $state.$current.data.pageTitle;
        }
        vm.contact = {};

        Principal.identity().then(function (account) {
            if (account) {
                vm.contact.email = account.email;
            }
        });

        function save(form) {
            if (form.$valid) {
                ContactService.save(vm.contact, function () {
                    vm.error = null;
                    vm.success = 'OK';
                    vm.contact.content = '';
                    form.content.$dirty = false;
                    setTimeout(function () {
                        vm.success = null;
                        $rootScope.$apply(vm.success);
                    }, 8000);
                }, function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            } else {
                angular.forEach(form.$error.required, function (field) {
                    field.$setDirty();
                });
            }
        }

        function logout() {
            Auth.logout();
            $state.go('home.login');
        }

    }
})();
