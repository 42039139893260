(function () {
    'use strict';

    angular.module('komexLoyaltyApp')
        .controller('MenuController', MenuController);

    MenuController.$inject = ['$scope', '$translate', '$sce', 'MenuService'];

    function MenuController($scope, $translate, $sce, MenuService) {
        $scope.menuItems = {};

    }
})();
