(function () {
    'use strict';

    angular
        .module('komexLoyaltyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('account.cms', {
                parent: 'account',
                url: '/{page}',
                data: {
                    pageTitle: ':pageTitle'
                },
                templateUrl: 'app/cms/cms-content.html',
                controller: 'CMSController',
                controllerAs: 'vm',
                resolve: {
                    cmsPage: ['$stateParams', 'PageContent', function ($stateParams, PageContent) {
                        return PageContent.get({name: $stateParams.page}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user-management');
                        return $translate.refresh();
                    }]

                }
            });
    }
})();
