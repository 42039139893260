(function () {
    'use strict';

    angular
        .module('komexLoyaltyApp')
        .factory('PartContent', PartContent);

    PartContent.$inject = ['$resource'];

    function PartContent($resource) {
        var service = $resource('api/part/:name', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }
})();
