(function () {
    'use strict';

    angular.module('komexLoyaltyApp')
        .directive('part', part);

    part.$inject = ['PartContent', '$sce'];

    /** @ngInject */
    function part(PartContent, $sce) {
        return {
            restrict: 'E',
            templateUrl: 'app/layouts/part/part.html',
            controller: 'PartController',
            controllerAs: 'vm',
            scope: {
                name: '@name',
                lang: '@lang'
            },
            link: function (scope, el) {
                PartContent.get({name: scope.name}).$promise.then(function (result) {
                    if (result) {
                        scope.partContent = result;
                        var pvalue = angular.fromJson(scope.partContent.pvalue);
                        scope.partContent.pvalue = $sce.trustAsHtml(pvalue[scope.partContent.pversion].pdata);
                    }
                })
            }
        };
    }
})();
