(function () {
    'use strict';

    angular
        .module('komexLoyaltyApp')
        .factory('CustomerLoyaltyService', CustomerLoyaltyService);

    CustomerLoyaltyService.$inject = ['$resource'];

    function CustomerLoyaltyService($resource) {
        var service = $resource('api/users/loyalty/:customerId', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
        return service;
    }
})();
