(function () {
    'use strict';

    angular.module('komexLoyaltyApp')
        .directive('login', login);

    login.$inject = ['$sce'];

    /** @ngInject */
    function login($sce) {
        return {
            restrict: 'E',
            templateUrl: 'app/components/login/login-form.html',
            controller: 'LoginController',
            controllerAs: 'vm',
            scope: {
                isAuthenticated: '=isAuthenticated'
            }
        };
    }
})();
