(function () {
    'use strict';

    angular
        .module('komexLoyaltyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('account', {
                abstract: true,
                data: {
                    pageTitle: 'Klub 5.10.15.'
                },
                templateUrl: 'app/account/account.html',
                controller: 'AccountController',
                controllerAs: 'vm',
                resolve: {
                    allPages: ['$stateParams', 'PagesAll', function ($stateParams, PagesAll) {
                        return PagesAll.get().$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('account');
                        return $translate.refresh();
                    }]
                },
            });

    }
})();
