(function() {
    'use strict';

    angular
        .module('komexLoyaltyApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state'];

    function HomeController ($scope, Principal, LoginService, $state) {
        var vm = this;
        vm.currentAccountView = 'login';
        vm.account = null;
        vm.isAuthenticated = false;
        vm.login = LoginService.open;
        vm.register = register;
        vm.getLogoHref = getLogoHref;

        $scope.$on('authenticationSuccess', function() {
            getAccount();

            $state.go('account.desktop');
        });

        // getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        function getLogoHref() {
            if (vm.isAuthenticated) {
                $state.go('account.desktop');
            } else {
                $state.go('home.login');
            }
        }
    }
})();
