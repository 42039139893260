(function () {
    'use strict';

    angular
        .module('komexLoyaltyApp')
        .controller('AgreementsController', AgreementsController);

    AgreementsController.$inject = ['$state', 'Principal', 'Auth', 'AgreementsService', '$window', '$rootScope'];

    function AgreementsController($state, Principal, Auth, AgreementsService, $window, $rootScope) {
        var vm = this;

        vm.error = null;
        vm.saveAgreements = saveAgreements;
        vm.userAgreements = {};
        vm.success = null;
        vm.logout = logout;
        vm.agreements = {};
        vm.agreementsAll = [];

        vm.everythingChecked = false;
        vm.checkEverything = checkEverything;

        if ($state.$current.data && $state.$current.data.pageTitle) {
            $window.document.title = $state.$current.data.pageTitle;
        }

        loadAllAgreements();

        Principal.identity(true).then(function (account) {
            if (account) {
                if (account.agreements) {
                    account.agreements.forEach(function (agreement) {
                        vm.userAgreements[agreement.id] = agreement.agreed;
                    });
                }
                vm.customerId = account.customerId;
            }
        });

        function loadAllAgreements() {
            AgreementsService.get().$promise.then(function (result) {
                if (result && result.agreements) {

                    result.agreements.forEach(function (agreement) {
                            vm.agreementsAll.push(agreement);
                            vm.agreements[agreement.id] = agreement;
                        }
                    );
                }
            }, function (result) {
                console.log('error: ' + JSON.stringify(result));
            });
        }

        function saveAgreements(agreementForm) {
            var agreementsValid = true;
            if (vm.agreementsAll) {
                for (var i = 1; i < vm.agreementsAll.length; i++) {
                    var agreement = vm.agreementsAll[i];
                    if (agreement.required && !vm.userAgreements[agreement.id]) {
                        agreementsValid = false;
                        break;
                    }
                }
            }

            if (agreementForm.$valid && agreementsValid) {
                var formattedAgreement = [];
                for (var agreement in vm.agreements) {
                    if (vm.userAgreements.hasOwnProperty(agreement)) {
                        if (agreement == 1) {
                            vm.userAgreements[agreement] = true;
                        }
                        formattedAgreement.push({id: agreement, agreed: vm.userAgreements[agreement]});
                    }
                }
                AgreementsService.save({agreements: formattedAgreement, customerId: vm.customerId}, function () {
                    vm.success = 'OK';
                    setTimeout(function () {
                        vm.success = null;
                        $rootScope.$apply(vm.success);
                    }, 8000);
                }, function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }

        function logout() {
            Auth.logout();
            $state.go('home.login');
        }

        function checkEverything() {
            vm.everythingChecked = !vm.everythingChecked;

            for (var agreement in vm.agreements) {
                if (agreement == 1) {
                    vm.userAgreements[agreement] = true;
                }
                vm.userAgreements[agreement] = vm.everythingChecked;
            }
        }
    }
})();
