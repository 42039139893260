(function () {
    'use strict';

    angular.module('komexLoyaltyApp')
        .directive('agreements', agreements);

    agreements.$inject = ['$sce'];

    /** @ngInject */
    function agreements($sce) {
        return {
            restrict: 'E',
            templateUrl: 'app/components/agreements/agreements.html',
            controller: 'AgreementsController',
            controllerAs: 'vm',
            scope: {
            }
        };
    }
})();
